import './App.css';
import Avatar from './Components/Avatar';

function App() {
  return (
      <div className="App">
        <Avatar />
      </div>
  );
}
export default App;
